import React, { useEffect } from 'react'
import BasicTabs from '../../../shared/hoc/tabs/Tabs'
import EventListPage from "../event-management/event-list";
import { useSelector } from 'react-redux';
import OngoingEventListPage from './ongoing-event-list';
import { gql, useMutation, useQuery } from '@apollo/client'


const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `



function EventListManagePage() {
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [requestEventId, setRequestEventId]: any = React.useState(null);
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });


    useEffect(() => {
        getLatestEvents();
    }, [])




    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);
        }
        return data;
    }

    return (
        <div>
            {
                decodedTokenData.roles !== "Evaluator" ? (
                    <BasicTabs tabsData={[
                        {
                            label: 'Upcoming Events', content: () => (
                                <OngoingEventListPage season={requestEventId} />
                            )
                        },
                        {
                            label: 'Completed Events', content: () => (
                                <EventListPage season={requestEventId} />
                            )

                        },

                    ]} />

                ) : (<BasicTabs tabsData={[
                    {
                        label: 'Upcoming Events', content: () => (
                            <OngoingEventListPage />
                        )
                    }
                ]} />)
            }

        </div>
    )
}

export default EventListManagePage

