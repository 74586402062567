import React, { useEffect, useState } from 'react'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import TextField from '../../../shared/widgets/text-field/TextField';
import { Button, Divider, Grid, MenuItem } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';


const CREATE_ASSOCIATION_QUERY = gql`mutation CreateAssociation($associationName: String!, $clubId: Int) {
    createAssociation(associationName: $associationName, clubId: $clubId) {
      success
      errors
      association {
        id
        associationName
      }
    }
  }
  `

const GET_CLUBS_QUERY = gql`query Query($search: String, $page: Int, $pageSize: Int) {
    getAllClubs(search: $search, page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
  `

const UPDATE_ASSOCIATION_QUERY = gql`
mutation Mutation($updateOneAssociationId: ID!, $associationName: String!) {
    updateOneAssociation(id: $updateOneAssociationId, associationName: $associationName) {
      association {
        Club {
          contactNumber
          clubName
        }
      }
      success
      errors
    }
  }
`


function Addassociation({ association, onSave, onClose, handleNext, editMode }: any) {

    const [resetField, setResetField] = useState<Function | undefined>(undefined);
    const [createAssociation] = useMutation(CREATE_ASSOCIATION_QUERY);
    const { loading: clubsLoading, error: clubsError, data: clubsData } = useQuery(GET_CLUBS_QUERY, {
        variables: {
            search: null, page: null
        }
    });
    const [updateAssociation] = useMutation(UPDATE_ASSOCIATION_QUERY);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    const { enqueueSnackbar } = useSnackbar();

    const handleSubmit = async (newAssociation: any, callback: Function) => {

        if (association) {

            const variables = {
                updateOneAssociationId: association.id,
                associationName: newAssociation.associationName
            }
            try {
                const { data } = await updateAssociation({
                    variables: variables
                });

                if (data.updateOneAssociation.errors?.length > 0) {
                
                    enqueueSnackbar(data.updateOneAssociation.errors[0], { variant: "error" });

                } else {
                
                    enqueueSnackbar(t("Association updated successfully"), { variant: "success" });
                    onSave();
                }
            } catch (error) {
                console.error('Mutation error:', error);
            }

        } else {

            const variables = {
                associationName: newAssociation.associationName,
                clubId: decodedTokenData.roles == 'SuperAdmin' ? parseInt(newAssociation.clubId) : parseInt(decodedTokenData.ClubId),
            }
            try {
                const { data } = await createAssociation({
                    variables: variables
                });

                if (data.createAssociation.errors?.length > 0) {
                    console.error('Error:', data.createAssociation.errors);
                    enqueueSnackbar(data.createAssociation.errors[0], { variant: "error" });
                    callback();
                } else {
                    enqueueSnackbar(t("Association created successfully"), { variant: "success" });
                    handleNext(data.createAssociation.association, variables.clubId);
                }
            } catch (error) {
                console.error('Mutation error:', error);
                callback();
            }

        }


    };


    return (
        <div>
            <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetField((name) => resetField)}>
                <FormField name='associationName' value={association?.associationName ?? ""} validator={(value: string) => {
                    if (!value) return "Association name is required."
                    return null
                }} validateOnChange={false}>
                    <TextField id="associationName"
                        label={t("Association Name")}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        fullWidth={true}
                    />
                </FormField>

                {
                    decodedTokenData.roles == 'SuperAdmin' && <FormField name='clubId' value={association?.Club?.id ?? ""} validator={(value: string) => {
                        if (!value) return "Club selection is required.";
                        return null
                    }} validateOnChange={false}>
                        <TextField id="role"
                            label={t("Club")}
                            select
                            variant="outlined"
                            sx={{ mb: 10 }}
                            readonly={(association?.Club?.id ?? "") != ""}
                            fullWidth={true}
                        >
                            {!clubsLoading && !clubsError ? clubsData.getAllClubs.clubs.map((club: any) => (
                                <MenuItem key={"club" + club.id} value={club.id}>
                                    {club.clubName}
                                </MenuItem>
                            )) : <MenuItem>{t('Loading')}</MenuItem>}
                        </TextField>
                    </FormField>
                }
                <Divider sx={{ mx: -24, mb: 20 }} />
                <Grid container justifyContent={"flex-end"}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
                        <Button type="submit" variant="contained" color="primary">{t('Save')}</Button>
                    </Grid>
                </Grid>
            </FormGroup>
        </div>
    )
}

export default Addassociation