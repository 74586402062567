import React, { useContext, useEffect, useMemo, useRef } from 'react'
import TablePageHoc from '../../../shared/hoc/table-list/table-list-hoc';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { TableColumn, WidgetProps } from '../../../shared/types/widget-types';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from "react";
import { gql } from '@apollo/client';
import ActionMenu from '../../../shared/widgets/action-menu/action-menu';
import { Box, MenuItem } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import ConfirmModal from '../../../shared/hoc/modal/confirm-modal';
import PlayerFilter from '../players-management/player-filter';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useUpdateEffect } from '../../../shared/custom-hooks/useUpdateEffect';
import { getWidget } from '../../../shared/widgets';
import AddPlayer from '../add-player/AddPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { responsiveString } from '../../../shared/utils/util-functions';
import EllipsisBox from '../../../shared/widgets/EllipsisBox/EllipsisBox';
import { useCookies } from 'react-cookie';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { Add, MoreVertOutlined, Upload } from '@mui/icons-material';
import FileUpload from '../upload-player/FileUpload';
import PlayerDetailModal from '../submitted-players-list/Player-Detail';
import ExistingAssociationPlayersList from './ExistingAssnPlayersList';
import AddAssociationPlayer from './addAssociationPlayer';

const DELETE_PLAYER_QUERY = gql`mutation DeleteAssociationPlayerById($deleteAssociationPlayerByIdId: ID!) {
    deleteAssociationPlayerById(id: $deleteAssociationPlayerByIdId) {
      success
      errors
    }
  }`

const GET_REQUEST_EVENTS_QUERY = gql`
  query Query {
      getRequestEvents {
        errors
        requestEvents {
          id
          ownerId
          from
          to
        }
        success
      }
    }
  `


type ChildRef = {
    refetchData: (data: any) => void;
    handleDeleterowPagination: () => void
};



function AssociationPlayersList() {

    const [page, setPage] = React.useState(1);
    const [searchText, setSearchText] = useState(null);
    const [addModalOpen, setAddModalOpen] = React.useState(false);
    const [selectedUser, setSelectedUser]: any[] = React.useState(null);
    const [deleteConfirmModal, setDeleteConfirmModal] = React.useState(false);
    const [downloadConfirmModal, setDownloadConfirmModal] = React.useState(false);
    const childRef = useRef<ChildRef | null>(null);
    const [filterData, setFilterData]: any = React.useState({});
    const [requestEvents, setRequestEvents] = useState<any>([]);
    const [requestEventId, setRequestEventId] = useState(null);
    const [selectedSeason, setSelectedSeason]: any = React.useState(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [initialised, setInitialised] = useState(false);
    const [clubDetailInfo, setClubDetailInfo] = React.useState({});
    const [ClubDetail, setClubDetailModal] = React.useState(false);
    const [index, setSelectedIndex] = React.useState(0);
    const [addExistingPlayersModalOpen, setAddExistingPlayersModalOpen] = React.useState(false);
    const [uploadModalOpen, setUploadModalOpen] = React.useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const [deletePlayer] = useMutation(DELETE_PLAYER_QUERY);
    const pageMeta = useContext(PageMetaContext);
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { refetch: latesteventfetch } = useQuery(GET_REQUEST_EVENTS_QUERY, { skip: true });


    const { requestEventIdParam } = useParams();

    const extractYear = (timestamp: any) => {
        const date = new Date(parseInt(timestamp));
        return date.getFullYear();
    };

    useEffect(() => {
        if (pageMeta) {
            const { setPageName, setBreadcrumbs } = pageMeta;
            setPageName("Playerlist");
        }
    }, [pageMeta]);





    const handleRefetch = () => {
        childRef.current?.refetchData({
            search: searchText ?? "",
            // clubId:filterData?.clubId,
            clubId: decodedTokenData.roles !== 'SuperAdmin' ? decodedTokenData.ClubId : filterData?.clubId,
            associationId: decodedTokenData.roles === 'AssociationAdmin' ? decodedTokenData.AssociationId : filterData?.associationId,
            requestEventId: filterData ? filterData.requestEventId : requestEventId,
        });
        setSelectedFile(undefined);
    };



    useEffect(() => {
        getLatestEvents();
    }, [])

    const getLatestEvents = async () => {
        const { data } = await latesteventfetch({
            search: "",
            page: null,
            pageSize: null
        });

        let reqId: any = null;

        if (data?.getRequestEvents.requestEvents.length > 0) {
            reqId = data?.getRequestEvents.requestEvents[0].id;
            setRequestEventId(reqId);
            setSelectedSeason(data?.getRequestEvents?.requestEvents[0]);
            setRequestEvents(data?.getRequestEvents.requestEvents);
        }


        let club = searchParams.get("club");
        let association = searchParams.get("association");
        setTimeout(() => {

            setFilterData({
                requestEventId: reqId,
                clubId: club,
                associationId: association
            });
            setInitialised(true)
        }, 1000)

        return data;
    }








    const deleteRow = async () => {
        setDeleteConfirmModal(false);
        if (!selectedUser) return;
        const variables = {
            deleteAssociationPlayerByIdId: selectedUser?.id,
        }

        try {
            const { data } = await deletePlayer({
                variables: variables
            });
            setSelectedUser(null);

            if (data.deleteAssociationPlayerById.errors?.length > 0) {
                console.error('Error:', data.deleteAssociationPlayerById.errors);
                enqueueSnackbar(data.deleteAssociationPlayerById.errors[0], { variant: "error" });
            } else {
                childRef?.current?.handleDeleterowPagination()
                enqueueSnackbar(t("Player deleted successfully"), { variant: "success" });

            }
        } catch (error) {
            setSelectedUser(null);
            console.error('Mutation error:', error);
        }

    }

    const [cookies] = useCookies(['token']);

    const dispatch = useDispatch()

    const token = cookies.token;

    const [selectedFile, setSelectedFile] = useState<File>();
    const [fileName, setFileName] = React.useState("");
    const [fileUpload, setFileUpload] = useState(false)



    const handleFileUpload = (event: any) => {
        console.log("hiii", event)
        const file = event.target.files[0];
        setSelectedFile(file);
        setFileUpload(true);
        setFileName(file.name);

    };


    const handleFileSubmit = async () => {

        try {

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);

                // const BASE_URL = "http://localhost:6767";
                // const BASE_URL = "https://evaluva-api.bixbytessolutions.com";
                const BASE_URL = "https://evalua-live-api.bixbytessolutions.com";

                const response = await fetch(BASE_URL + '/excelFile-import-association-player', {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                    body: formData
                });
                const response_data = await response.json()
                if (response.status === 200) {
                    enqueueSnackbar(t("Player data uploaded successfully"), { variant: "success" });
                    setUploadModalOpen(false)
                    setSelectedFile(undefined)
                    setFileName('');

                    handleRefetch()
                } else if (response.status === 404) {
                    enqueueSnackbar(response_data.message, { variant: "error" });
                } else if (response.status === 400) {
                    console.log(response)
                    enqueueSnackbar(response_data.message, { variant: "error" });
                }
            } else {
                console.log(selectedFile)
                enqueueSnackbar(t("No file selected"), { variant: "error" });
            }


        } catch (errors) {
            console.log('An error occurred while uploading the player list:', errors)
        }

    }

    const download = async () => {
        setDownloadConfirmModal(false);

        try {
            // const BASE_URL = "http://localhost:6767";
            // const BASE_URL = "https://evaluva-api.bixbytessolutions.com";
            const BASE_URL = "https://evalua-live-api.bixbytessolutions.com";
            const response = await fetch(BASE_URL + '/excelFile-download-assnplayers?' + new URLSearchParams({
                search: searchText ?? "",
                clubId: decodedTokenData.roles !== 'SuperAdmin' ? decodedTokenData.ClubId : filterData?.clubId ?? "",
                associationId: decodedTokenData.roles === 'AssociationAdmin' ? decodedTokenData.AssociationId : filterData?.associationId ?? "",
                requestEventId: filterData ? filterData.requestEventId : requestEventId,
            }), {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
            });
            if (response.status === 200) {
                enqueueSnackbar(t("Player list downloaded successfully"), { variant: "success" });
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const currentDate = new Date();
                const year = currentDate.getFullYear();
                const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
                const day = currentDate.getDate().toString().padStart(2, '0');
                const hours = currentDate.getHours().toString();
                const minutes = currentDate.getMinutes().toString();

                const currentDateTime = `${month}_${day}_${year}_${hours}:${minutes}`;

                link.download = `${t('Playerlist')}-${currentDateTime}.xlsx`;

                link.target = '_blank';


                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);

                URL.revokeObjectURL(link.href);
            }
            else if (response.status === 404) {
                enqueueSnackbar(t("No players were found for downloading"), { variant: "error" });
            }
            ;

        } catch (error) {
            console.log('An error occurred while downloading the player list:', error);
        }
    }

    const onSearchTextChange = (event: any) => {
        if (searchText !== event.target.value) {

            setPage(1)
        }
        setSearchText(event.target.value)
    };


    useUpdateEffect(() => {
        handleRefetch();
    }, [filterData, searchText], 300)


    const handleFilterChange = (filters: any) => {
        if (filters.submit) {
            setPage(1)
            let reqId = filters.filters["requestEventId"] ?? "";

            setFilterData(filters.filters);
            let selectedSeason = requestEvents.filter((event: any) => event['id'].toString() == reqId.toString());
            if (selectedSeason.length > 0) {
                setSelectedSeason(selectedSeason[0]);
            }
        }
    };


    const ActionBarConfig = useMemo(() => {
        const config = [
            ...((selectedSeason != null) ? [
                {
                    type: "label-season",
                    label: `Season ${extractYear(selectedSeason.from)}-${extractYear(selectedSeason.to)}`,
                    sx: {
                        marginRight: 'auto'
                    }
                }
            ] : []),
            {
                type: "filter-menu",
                children: <PlayerFilter filterData={filterData} onFilterChange={handleFilterChange} />,
                sx: {
                    order: 1
                }
            },
            {
                type: "search-input",
                placeholder: "Search",
                onChange: onSearchTextChange,
                responsive: {
                    xs: 12,
                    sm: "auto"
                },
                sx: {
                    order: {
                        xs: 4,
                        sm: 2
                    }
                }
            },
            {
                type: "button",
                label: t(responsiveString({ xs: "Import", sm: "Import" })),
                startIcon: <Upload />,
                onClick: () => setUploadModalOpen(true),
                sx: {
                    order: {
                        xs: 2,
                        sm: 3
                    }
                }
            },

            ...(
                decodedTokenData?.roles !== 'Evaluator' ? (
                    [{
                        type: "stepper-button",
                        options: [t('Add Player'), t('Add Existing Player')],
                        selectedIndex: index,

                        startIcon: <Add />,
                        onClick: (index: number) => {

                            setSelectedIndex(index)

                            if (index === 0) {
                                setAddModalOpen(true)
                            } else {
                                setAddExistingPlayersModalOpen(true)
                            }

                        },
                        sx: {
                            order: {
                                xs: 2,
                                sm: 3
                            }
                        }
                    }]
                ) : []
            ),
            {
                type: "button",
                label: "Download",
                startIcon: <DownloadIcon />,
                variant: "contained",
                color: 'secondary',
                onClick: () => setDownloadConfirmModal(true),
                sx: {
                    order: {
                        xs: 3,
                        sm: 4
                    }
                }
            },
        ]
        return config;
    }, [filterData, decodedTokenData]);





    const TableConfig = useMemo(() => {
        return [
            ...((decodedTokenData.roles !== 'Evaluator') ? (
                [
                    {
                        label: "Action",
                        align: "left",
                        accessor: (row: any) => {
                            return <>
                                <ActionMenu
                                    button={{
                                        type: "icon-button",
                                        icon: <MoreVertOutlined />,
                                        size: 'small',
                                        color: 'primary',
                                        sx: { color: 'white' }
                                    }}
                                    children={
                                        [
                                            <MenuItem onClick={() => {
                                                setSelectedUser(row);
                                                setAddModalOpen(true)
                                            }}>{t('Edit')}</MenuItem>,
                                            <MenuItem onClick={() => {
                                                setSelectedUser(row);
                                                setDeleteConfirmModal(true)
                                            }}>{t('Delete')}</MenuItem>
                                        ]
                                    }
                                />
                            </>
                        }
                    }
                ]
            ) : (
                []
            ))
            ,
            {
                label: "First Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row?.firstName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Last Name",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.lastName} minWidth={250} lineClamp={2} />
            },
            {
                label: "Date Of Birth",
                align: "left",
                accessor: (row: any) => row.dateOfBirth
            },
            {
                label: "Street",
                align: "left",
                accessor: (row: any) => <EllipsisBox content={row.playerAddress} minWidth={300} lineClamp={2} />
            },
            {
                label: "Post Code",
                align: "left",
                accessor: (row: any) => row.postCode
            },
            {
                label: "City",
                align: "left",
                accessor: (row: any) => row.city
            },
            {
                label: "Contact Person Name",
                align: "left",
                accessor: (row: any) => row.contactPersonName
            },
            {
                label: "Relationship",
                align: "left",
                accessor: (row: any) => t(row.relationship)
            },
            {
                label: "Telephone",
                align: "left",
                accessor: (row: any) => row.telephone
            },
            {
                label: "Email",
                align: "left",
                accessor: (row: any) => row.emailAddressImpFatherOrMother
            },
            {
                label: "Nationality",
                align: "left",
                accessor: (row: any) => row.nationality
            },
            {
                label: "Association",
                align: "left",
                accessor: (row: any) => row.Association?.associationName
            },
            {
                label: "Strong Foot",
                align: "left",
                accessor: (row: any) => t(row.strongFoot)
            },
            {
                label: "Goal Keeper",
                align: "left",
                accessor: (row: any) => t(row.goalKeeper)
            }
        ];
    }, [decodedTokenData])


    const QUERY = `query GetAssociationPlayerList($requestEventId: ID, $associationId: ID, $search: String, $page: Int, $pageSize: Int, $eventId: Int, $slotId: Int) {
        getAssociationPlayerList(requestEventId: $requestEventId, associationId: $associationId, search: $search, page: $page, pageSize: $pageSize, eventId: $eventId, slotId: $slotId) {
          errors
          success
          totalPlayers
          players {
            telephone
            strongFoot
            relationship
            postCode
            playerAddress
            nationality
            lastName
            goalKeeper
            id
            firstName
            emailAddressImpFatherOrMother
            dateOfBirth
            contactPersonName
            city
            RequestEventId
            RequestEvent {
              from
              to
              ownerId
              id
            }
            Association {
              associationName
            }
          }
        }
      }
      `




    const onAddUserModalClose = () => {
        setAddModalOpen(false);
        setSelectedUser(null);
        handleRefetch();
    }


    const onAddExistingPlayersModalOpen = () => {
        setAddExistingPlayersModalOpen(false);
        setSelectedUser(null);
        handleRefetch();
    }

    if (!filterData.requestEventId && !initialised) {
        return <div></div>
    }

    const handleClose = () => {
        setUploadModalOpen(false);
        setSelectedFile(undefined);
        setFileName('');
    };


    return (
        <div>
            <TablePageHoc actions={ActionBarConfig as WidgetProps[]} columns={TableConfig as TableColumn[]} ref={childRef} query={QUERY}

                params={{
                    search: searchText,
                    requestEventId: filterData ? filterData.requestEventId : requestEventId,
                }}
                sourceAccessor={(data) => data?.getAssociationPlayerList?.players}
                countAccessor={(data) => data?.getAssociationPlayerList?.totalPlayers}


                // detailPointer={decodedTokenData.roles === 'AssociationAdmin' ? "pointer" : undefined}
                // onRowClick={decodedTokenData.roles === 'AssociationAdmin' ? (row) => {

                //     setClubDetailInfo(row)
                //     setClubDetailModal(true)
                // } : undefined}

                pagination={{
                    enable: true,
                    page: page,
                    onChange: (page) => {
                        setPage(page)
                    }
                }} />

            {
                getWidget(
                    {
                        type: "modal",
                        title: selectedUser ? "Edit Player" : "Add Player",
                        modalOpen: addModalOpen,
                        onClose: () => onAddUserModalClose(),
                        children: <AddAssociationPlayer player={selectedUser} requestId={requestEventId} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddModalOpen(false)
                            setSelectedUser(null)
                        }} />,
                        size: 'lg'
                    })
            }

            {
                getWidget(
                    {
                        type: "modal",
                        title: "Add Existing Player",
                        modalOpen: addExistingPlayersModalOpen,
                        onClose: () => onAddExistingPlayersModalOpen(),
                        children: <ExistingAssociationPlayersList player={selectedUser} requestId={requestEventId} onSave={() => onAddUserModalClose()} onClose={() => {
                            setAddExistingPlayersModalOpen(false)
                            setSelectedUser(null)
                        }} />,
                        size: 'lg'
                    })
            }
            {
                getWidget(
                    {
                        type: "modal",
                        title: t('Upload File'),
                        modalOpen: uploadModalOpen,
                        onClose: () => setUploadModalOpen(false),
                        children: <FileUpload handleSubmit={handleFileSubmit} fileName={fileName} handleChange={(event: any) => handleFileUpload(event)} fileUpload={fileUpload} onClose={handleClose} />,
                        size: 'md'
                    })
            }



            <ConfirmModal modalOpen={deleteConfirmModal} onClose={() => setDeleteConfirmModal(false)} onConfirm={() => deleteRow()} title={t('Are you sure?')} children={`${t('Do you want to delete the player')} ${selectedUser?.firstName}? ${t('Once deleted, this action cannot be undone!')}`} />
            <ConfirmModal modalOpen={downloadConfirmModal} onClose={() => setDownloadConfirmModal(false)} onConfirm={() => download()} title={t('Are you sure?')} children={t('Would you like to download the players list now?')} />
            <PlayerDetailModal modalOpen={ClubDetail} onClose={() => setClubDetailModal(false)} detail={clubDetailInfo} />
        </div>
    )
}

export default AssociationPlayersList