import React, { useEffect, useState } from 'react'
import TextField from '../../../shared/widgets/text-field/TextField'
import { Box, Button, Grid, MenuItem } from '@mui/material'
import { FormField, FormGroup } from '../../../shared/utils/form-generator';
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';



const GET_CLUBS_QUERY = gql`query Query($page: Int, $pageSize: Int) {
    getAllClubs(page: $page, pageSize: $pageSize) {
      clubs {
        clubAddress
        clubName
        contactNumber
        id
      }
      errors
      success
      totalClubs
    }
  }
  `

const GET_ASSOCIATIONS_QUERY = gql`query GetAllAssociations($clubId: Int, $search: String, $page: Int, $pageSize: Int) {
    getAllAssociations(clubId: $clubId, search: $search, page: $page, pageSize: $pageSize) {
      associations {
        associationName
        id
      }
      errors
      success
      totalAssociations
    }
  }`



function FilterUser({ filterData, onFilterChange, onClose }: any) {

    const { t } = useTranslation();
    const [resetForm, setResetForm] = useState<any>(null);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [clubsData, setClubsData] = useState<any>(null);
    const [associationData, setAssociationData] = useState<any>(null);



    const { refetch: clubRefetch } = useQuery(GET_CLUBS_QUERY, { skip: true });
    const { refetch: associationFetch } = useQuery(GET_ASSOCIATIONS_QUERY, { skip: true });

    useEffect(() => {
        if (decodedTokenData.roles == 'SuperAdmin') {
            getClubData();
        }

        if (decodedTokenData.roles !== 'AssociationAdmin' || filterData?.clubId) {
            let clubId = filterData?.clubId ?? decodedTokenData.ClubId;
            if (clubId) {
                getAssociationData(parseInt(clubId))
            }
        }
    }, [decodedTokenData])

    const getClubData = async () => {
        const { data } = await clubRefetch({
            search: "",
            page: null,
            pageSize: null
        });
        setClubsData(data);
        return data;
    }


    const getAssociationData = async (clubId: any) => {
        if (!clubId) return;
        const { data } = await associationFetch({
            search: "",
            page: null,
            pageSize: null,
            clubId
        });
        setAssociationData(data);
        return data;
    }




    const SuperAdminroles = [
        {
            value: 'SuperAdmin',
            label: 'Super Admin',
        },
        {
            value: 'ClubAdmin',
            label: 'Club Admin',
        },
        {
            value: 'AssociationAdmin',
            label: 'Association Admin',
        }

    ];


    const ClubAdminRoles = [

        {
            value: 'ClubAdmin',
            label: 'Club Admin',
        },
        {
            value: 'AssociationAdmin',
            label: 'Association Admin',
        },
        {
            value: 'Evaluator',
            label: 'Evaluator',
        },

    ];


    const AssnAdminRoles = [

        {
            value: 'AssociationAdmin',
            label: 'Association Admin',
        },
        {
            value: 'Evaluator',
            label: 'Evaluator',
        },

    ];



    const handleSubmit = async (user: any, callback: Function) => {

        const filters = {
            role: user.role || null,
            clubId: parseInt(user.clubId) || null,
            associationId: parseInt(user.associationId) || null,
        };

        onFilterChange({
            submit: true,
            filters: filters,
            callback
        });

        onClose();

    };


    return (
        // <div>
        <FormGroup onSubmit={handleSubmit} onFormInit={(resetField, resetForm) => setResetForm(() => resetForm)}>
            <Box sx={{ width: '300px', padding: '8px 16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {
                    decodedTokenData.roles == 'SuperAdmin' ? (
                        <FormField name='role' value={filterData?.role ?? ""} >
                            <TextField id="roles"
                                label={t("Role")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            >
                                {SuperAdminroles.map((option) => (
                                    <MenuItem key={option.value} value={option.value} >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </FormField>

                    ) : (
                        <FormField name='role' value={filterData?.role ?? ""}>
                            <TextField id="roles"
                                label={t("Role")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                            >
                                {
                                    decodedTokenData.roles === "ClubAdmin" ? (
                                        ClubAdminRoles.map((option) => (
                                            <MenuItem key={option.value} value={option.value} >
                                                {t(option.label)}
                                            </MenuItem>
                                        ))

                                    ) : (
                                        AssnAdminRoles.map((option) => (
                                            <MenuItem key={option.value} value={option.value} >
                                                {t(option.label)}
                                            </MenuItem>
                                        )
                                        )
                                    )
                                }

                            </TextField>
                        </FormField>
                    )
                }

                {
                    decodedTokenData.roles == 'SuperAdmin' && (
                        <FormField name='clubId' value={filterData?.clubId ?? ""}>
                            <TextField id="clubs"
                                label={t("Club")}
                                select
                                variant="outlined"
                                sx={{ mb: 10 }}
                                fullWidth={true}
                                onChange={(e: any) => {
                                    getAssociationData(parseInt(e.target.value))
                                }}
                            >

                                {clubsData ? clubsData.getAllClubs.clubs.map((club: any) => (
                                    <MenuItem key={"club" + club.id} value={club.id}>
                                        {club.clubName}
                                    </MenuItem>
                                )) : <MenuItem>{t('Loading')}</MenuItem>}
                            </TextField>
                        </FormField>

                    )
                }

                {
                    decodedTokenData.roles !== 'AssociationAdmin' && (

                        <FormField name='associationId' value={filterData?.associationId ?? ""}>
                            <TextField id="associations"
                                label={t("Association")}
                                select
                                variant="outlined"
                                fullWidth={true}
                                sx={{ mb: 10 }}
                            >

                                {associationData ? associationData.getAllAssociations.associations.map((association: any) => (
                                    <MenuItem key={"assoc" + association.id} value={association.id} >
                                        {association.associationName}
                                    </MenuItem>
                                )) : <MenuItem></MenuItem>}
                            </TextField>
                        </FormField>
                    )
                }


                <Grid sx={{ alignSelf: 'end' }}>
                    <Button type="button" variant="text" onClick={() => {
                        resetForm();
                        // setAssociationData(null)
                        { decodedTokenData.roles !== "ClubAdmin" && setAssociationData(null) }
                        onFilterChange({
                            submit: true,
                            filters: {
                                role: null,
                                clubId: null,
                                associationId: null,
                            },
                        });
                        // onClose();
                    }} color="primary">{t('Clear')}</Button>
                    <Button type="submit" variant="contained" color="primary">{t('Submit')}</Button>
                </Grid>
            </Box>
        </FormGroup>
        // </div>
    )
}

export default FilterUser