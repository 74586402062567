import React, { useContext, useEffect } from 'react'
import PageMetaContext from '../../../shared/hoc/admin-layout/AdminLyout.provider';
import { useTranslation } from 'react-i18next'
import { stopPageLoading } from '../../../store/reducers/AppSlice';
import { useDispatch } from 'react-redux';

export default function DashBoard() {

  const {t} = useTranslation();
  const dispatch = useDispatch();


    const pageMeta = useContext(PageMetaContext);

    useEffect(() => {
      if (pageMeta) {
        const { setPageName, setBreadcrumbs } = pageMeta;
        setPageName("DashBoard");
      }
      dispatch(stopPageLoading())
    }, [pageMeta]);

  return (
    <div>
      {t('DashBoard')}
    </div>

  )
}
