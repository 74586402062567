import React, { useTransition, useState, useEffect, useRef } from 'react'
import { Box, Button, ButtonGroup, DialogContentText, Divider, Grid, MenuItem, Tab, Tabs, Typography } from '@mui/material';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import TextField from '../../../shared/widgets/text-field/TextField';


const SUBMIT_EVALUATION_QUERY = gql`mutation Mutation($slotId: Int!, $slotPlayerId: Int!, $optionId: Int, $evaluationTime: String, $comment: String , $strongFoot: String) {
    createEventSlotPlayerEvaluvation(slotId: $slotId ,slotPlayerId: $slotPlayerId, optionId: $optionId, evaluationTime: $evaluationTime, comment: $comment, strongFoot: $strongFoot) {
      success
      errors
    }
  }
`



function PlayerEvaluation({ player, onSave, onClose, eventDetails }: any) {

    const [createEvaluation] = useMutation(SUBMIT_EVALUATION_QUERY);
    const decodedTokenData = useSelector((state: any) => state.auth.decodedTokenData)
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedOption, setSelectedOption] = useState(null);
    const [comment, setComment] = useState(null);
    const [option, setOption] = useState(null);
    const [foot, setFoot] = useState(player.strongFoot);


    const commentInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (commentInputRef.current && comment) {
            setTimeout(() => {
                commentInputRef.current?.focus();
            }, 0);
        }
    }, [comment]);


    const { enqueueSnackbar } = useSnackbar();

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const playerSlotId = searchParams.get('slotId') ?? ""

    useEffect(() => {
        if (decodedTokenData.roles === 'Evaluator' && player.optionCounts.length > 0) {
            setSelectedOption(player.optionCounts[0].optionId);
            setComment(player.optionCounts[0].comment);
        }
        else if (decodedTokenData.roles === 'ClubAdmin' && player.selfCounts.length > 0) {
            setSelectedOption(player.selfCounts[0].optionId);
            setComment(player.selfCounts[0].comment);
        }
        else {
            setSelectedOption(null);
            setComment(null);
        }
    }, []);


    const handleSubmit = () => {
        
        handleEvlauation(selectedOption);
        // if (selectedOption === null) {
        //     enqueueSnackbar(t("Please select the rating for player"), { variant: "error" });
        // } else {
        //     handleEvlauation(selectedOption);
        // }

    }




    const handleEvlauation = async (selectedOption: any) => {
    

        const variables = {
            slotId: parseInt(playerSlotId),
            slotPlayerId: parseInt(player.slotPlayerId),
            optionId: parseInt(selectedOption),
            evaluationTime: new Date(),
            comment: comment,
            strongFoot: foot
        }

        

        try {
            const { data } = await createEvaluation({
                variables: variables
            });
            if (data.createEventSlotPlayerEvaluvation.errors?.length > 0) {
                console.error('Error:', data.createEventSlotPlayerEvaluvation.errors);
                const errorIndex = data.createEventSlotPlayerEvaluvation.errors.length
                enqueueSnackbar(data.createEventSlotPlayerEvaluvation.errors[errorIndex - 1], { variant: "error" });
            } else {
                
                enqueueSnackbar(t("Evaluation submitted"), { variant: "success" });
                setComment(null);
                onSave();
            }

        } catch (error) {
            console.error('Mutation error:', error);
        }

    }



    return (
        <div>

            <Grid container spacing={12}>

                <Grid item xs={12} sm={decodedTokenData.roles === 'ClubAdmin' ? 3 : 4}>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '12px', 'marginBottom': '0px', "color": "#6b6868" }}>
                        {t("Jersey Number")}
                    </Typography>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '16px', 'marginBottom': '0px', 'fontWeight': '500' }}>
                        {player?.slotPlayerJerseyNumber}
                    </Typography>
                </Grid>


                <Grid item xs={12} sm={decodedTokenData.roles === 'ClubAdmin' ? 3 : 4}>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '12px', 'marginBottom': '0px', "color": "#6b6868" }}>
                        {t("Player Name")}
                    </Typography>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '16px', 'marginBottom': '0px', 'fontWeight': '500' }}>
                        {player?.firstName}
                    </Typography>
                </Grid>

                {
                    decodedTokenData.roles === 'ClubAdmin' && (
                        <Grid item xs={12} sm={decodedTokenData.roles === 'ClubAdmin' ? 3 : 4}>
                            <Typography component="p" gutterBottom sx={{ 'fontSize': '12px', 'marginBottom': '0px', "color": "#6b6868" }}>
                                {t("Association Name")}
                            </Typography>
                            <Typography component="p" gutterBottom sx={{ 'fontSize': '16px', 'marginBottom': '0px', 'fontWeight': '500' }}>
                                {player?.associationName}
                            </Typography>
                        </Grid>

                    )
                }


                <Grid item xs={12} sm={decodedTokenData.roles === 'ClubAdmin' ? 3 : 4}>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '12px', 'marginBottom': '0px', "color": "#6b6868" }}>
                        {t("Layout Name")}
                    </Typography>
                    <Typography component="p" gutterBottom sx={{ 'fontSize': '16px', 'marginBottom': '0px', 'fontWeight': '500' }}>
                        {eventDetails?.EventLayout?.title}
                    </Typography>
                </Grid>




                <Grid item xs={12}>
                    <Grid container spacing={0} marginTop={'10px'} marginBottom={'10px'}>

                        <RadioGroup
                            aria-label="evaluation-options"
                            name="evaluation-options"
                            value={selectedOption}
                            sx={{ 'width': '100%' }}
                            row
                            onChange={(event: any) => setSelectedOption(event.target.value)}
                        >

                            {eventDetails?.EventLayout?.layoutOptions.map((option: any) => {

                                if (option.subOptions.length === 0) {
                                    return (
                                        <>
                                            <Grid item xs={3} justifyContent={'space-between'}>
                                                <FormControlLabel
                                                    value={option.id}
                                                    control={<Radio />}
                                                    label={option.optionTitle}
                                                // onChange={() => setOption(option)}
                                                />
                                            </Grid>
                                        </>
                                    )
                                } else {
                                    return (
                                        <>
                                            <Grid item xs={12}>
                                                <div style={{ 'fontSize': '16px', 'marginTop': '20px', 'color': '#6B6868' }}>{option.optionTitle}</div>
                                            </Grid>


                                            {
                                                option.subOptions.map((subOption: any) => {
                                                    return (
                                                        <>
                                                            <Grid item xs={3} >
                                                                <FormControlLabel
                                                                    key={subOption.optionTitle}
                                                                    value={subOption.id}
                                                                    control={<Radio />}
                                                                    label={subOption.optionTitle}
                                                                // onChange={() => setOption(subOption)}
                                                                />
                                                            </Grid>

                                                        </>
                                                    )

                                                })
                                            }

                                        </>
                                    )
                                }

                            }

                            )}


                        </RadioGroup>

                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TextField id="foot"
                        label={t("Strong Foot")}
                        value={foot}
                        select
                        variant="outlined"
                        fullWidth={true}
                        onChange={(event: any) => setFoot(event.target.value)}
                    >
                        <MenuItem value="Right" >
                            {t('Right')}
                        </MenuItem>
                        <MenuItem value="Left" >
                            {t('Left')}
                        </MenuItem>
                    </TextField>
                </Grid>

                <Grid item xs={12}>
                    <TextField id="comment"
                        label={t("Comment")}
                        ref={commentInputRef}
                        variant="outlined"
                        sx={{ mb: 10 }}
                        multiline
                        style={{ 'marginTop': '10px' }}
                        value={comment}
                        maxRows={3}
                        fullWidth={true}
                        autoFocus
                        onChange={(event: any) => setComment(event.target.value)}
                    />

                </Grid>





                <Grid container justifyContent={"flex-end"} marginTop={'15px'}>
                    <Grid item xs={"auto"}>
                        <Button type="button" variant="text" onClick={onClose} color="secondary">{t('Cancel')}</Button>
                    </Grid>
                    <Grid item xs={"auto"}>
                        {/* {editMode ? <Button type="submit" variant="contained" color="primary">{t('Save')}</Button> : <Button type="submit" variant="contained" color="primary">{t('Next')}</Button>} */}
                        <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>{t('Submit')}</Button>
                    </Grid>
                </Grid>

            </Grid>
        </div >
    )
}

export default PlayerEvaluation